<template>
  <div>
    <v-card>
      <v-card-title>
        <v-container class="ml-0">
          <v-row>
            <v-col cols="3" class="pt-0"
              ><v-select
                v-model="selectedSearchableField.name"
                :items="searchableFields"
                label="Search Field"
              ></v-select
            ></v-col>
            <v-col cols="6" class="pt-0"
              ><v-text-field
                v-model="selectedSearchableField.value"
                append-icon="mdi-magnify"
                label="Search Value"
                @keyup.enter="findClicked"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
            <v-col>
              <v-btn
                id="findBtn"
                @click="findClicked"
                :disabled="isFindButtonDisabled || isLoading"
                >Find</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
    </v-card>
    <v-chip-group
          mandatory
          active-class="primary"
        >
          <v-chip
            v-for="equipment in currentEquipment"
            :key="equipment.id"
            @click="chipClicked(equipment)"
          >
            {{ equipment.number }}
          </v-chip>
        </v-chip-group>
    <equipment-log-history :logEntries="filteredByEquipmentId"></equipment-log-history>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import dateMixin from '../mixins/dateMixins';
import carrierMixin from '../mixins/carrierMixins';
import EquipmentLogHistory from './equipmentLogHistory.vue';

export default {
  components: { EquipmentLogHistory },
  data: () => ({
    currentHeaders: [
      { text: 'Arrived On', value: 'time' },
      { text: 'Equipment Number', value: 'number' },
      { text: 'Type', value: 'type' },
      { text: 'Size', value: 'size' },
      { text: 'State', value: 'state' },
      { text: 'SCAC/Carrier', value: 'scacCarrier' },
    ],
  }),
  mixins: [dateMixin, carrierMixin],
  computed: {
    currentEquipment() {
      if (this.log.length > 0) {
        const distinctEquipment = _.uniqBy(this.log, 'equipment.id');
        return _.map(distinctEquipment, 'equipment');
      }

      return [];
    },
    isFindButtonDisabled() {
      return this.selectedSearchableField.value.length < 3;
    },
    ...mapGetters('equipmentLog', [
      'searchableFields',
      'selectedSearchableField',
      'log',
      'isLoading',
      'filteredByEquipmentId',
    ]),
  },
  methods: {
    ...mapActions('equipmentLog', ['setSelectedSearchableFieldName', 'getLog', 'setEquipmentIdFilter']),
    async findClicked() {
      await this.getLog();
      const hasCurrentEquipment = this.currentEquipment.length > 0;
      if (hasCurrentEquipment) {
        this.setEquipmentIdFilter(this.currentEquipment[0].id);
      }
    },
    chipClicked(equipment) {
      this.setEquipmentIdFilter(equipment.id);
    },
  },
  created() {
    this.setSelectedSearchableFieldName(this.searchableFields[0]);
  },
};
</script>
