<template>
<div>
  <equipment-log-search />
</div>
</template>

<script>
import { mapActions } from 'vuex';
import EquipmentLogSearch from '@/components/equipmentLogSearch.vue';

export default {
  components: { EquipmentLogSearch },
  methods: {
    ...mapActions('equipmentLog', ['resetState']),
  },
  created() {
    this.resetState();
  },
};
</script>
