<template>
  <div>
    <v-card>
      <v-card-title v-if="!this.condensed">
        History
        <v-spacer> </v-spacer>
      </v-card-title>
      <v-data-table
        :id="id"
        :loading="isLoading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="logEntries"
        :items-per-page="50"
        :footer-props="this.footerProps"
        :hide-default-footer="this.condensed"
        style="text-align: left"
      >
        <template v-slot:item.previous="{ item }">
          <tr>
            <td v-if="item.previousLocation">
              <span>Zone: {{ formatGroups(item.previousLocation.groups) }}</span>
              <br /><span>Spot: {{ item.previousLocation.displayName }}</span>
            </td>
          </tr>
          <tr>
            <td v-if="item.previousEquipment">
              <span v-if="item.previousEquipment.number != item.updatedEquipment.number">
                Number: {{ item.previousEquipment.number }}<br />
              </span>
              <span v-if="item.previousEquipment.type != item.updatedEquipment.type">
                Type: {{ item.previousEquipment.type }}<br />
              </span>
              <span v-if="item.previousEquipment.size != item.updatedEquipment.size">
                Size: {{ item.previousEquipment.size }}<br />
              </span>
              <span v-if="item.previousEquipment.state != item.updatedEquipment.state">
                State: {{ item.previousEquipment.state }}<br />
              </span>
              <span v-if="getScacOrCarrierName(item.previousEquipment.carrier)
              != getScacOrCarrierName(item.updatedEquipment.carrier)">
                SCAC/Carrier: {{ getScacOrCarrierName(item.previousEquipment.carrier) }}
              </span>
              <span v-if="item.previousEquipment.bookingNumber != item.updatedEquipment.bookingNumber">
                Booking #: {{item.previousEquipment.bookingNumber}}<br />
              </span>
              <span v-if="item.previousEquipment.customer.name != item.updatedEquipment.customer.name">
                Customer: {{item.previousEquipment.customer.name}}<br />
              </span>
            </td>
            <td v-else></td>
          </tr>
        </template>
        <template v-slot:item.updated="{ item }">
          <tr>
            <td v-if="item.updatedLocation">
              <span>Zone: {{ formatGroups(item.updatedLocation.groups) }}</span>
              <br /><span>Spot: {{ item.updatedLocation.displayName }}</span>
            </td>
          </tr>
          <tr>
            <td v-if="item.updatedEquipment">
              <span v-if="item.previousEquipment.number != item.updatedEquipment.number">
                Number: {{ item.updatedEquipment.number }}<br />
              </span>
              <span v-if="item.previousEquipment.type != item.updatedEquipment.type">
                Type: {{ item.updatedEquipment.type }}<br />
              </span>
              <span v-if="item.previousEquipment.size != item.updatedEquipment.size">
                Size: {{ item.updatedEquipment.size }}<br />
              </span>
              <span v-if="item.previousEquipment.state != item.updatedEquipment.state">
                State: {{ item.updatedEquipment.state }}<br />
              </span>
              <span v-if="getScacOrCarrierName(item.previousEquipment.carrier)
              != getScacOrCarrierName(item.updatedEquipment.carrier)"
              >
                SCAC/Carrier: {{ getScacOrCarrierName(item.updatedEquipment.carrier) }}
              </span>
              <span v-if="item.previousEquipment.bookingNumber != item.updatedEquipment.bookingNumber">
                Booking #: {{item.updatedEquipment.bookingNumber}}<br />
              </span>
              <span v-if="item.previousEquipment.customer.name != item.updatedEquipment.customer.name">
                Customer: {{item.updatedEquipment.customer.name}}<br />
              </span>
            </td>
            <td v-else></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import dateMixin from '../mixins/dateMixins';
import carrierMixin from '../mixins/carrierMixins';

export default {
  props: ['logEntries', 'condensed'],
  mixins: [carrierMixin, dateMixin],
  computed: {
    ...mapGetters('equipmentLog', ['isLoading']),
    headers() {
      const headers = [
        {
          text: 'Time',
          value: 'formattedDate',
          sort(a, b) {
            const date1 = new Date(a);
            const date2 = new Date(b);
            return date1 - date2;
          },
        },
        { text: 'By', value: 'by' },
        { text: 'Action Type', value: 'type' },
        { text: 'Size', value: 'equipment.size' },
        { text: 'State', value: 'equipment.state' },
        { text: 'SCAC', value: 'equipment.carrier.scac' },
        { text: 'Previous', value: 'previous', sortable: false },
        { text: 'Updated', value: 'updated', sortable: false },
      ];
      if (!this.condensed) {
        headers.splice(3, 0, { text: 'Direction', value: 'direction' },
          { text: 'Equipment Number', value: 'equipment.number' },
          { text: 'Type', value: 'equipment.type' });
        headers.splice(9, 0, { text: 'Carrier', value: 'equipment.carrier.name' },
          { text: 'Booking #', value: 'equipment.bookingNumber' },
          { text: 'Customer', value: 'equipment.customer.name' });
      }
      return headers;
    },
    footerProps() {
      if (!this.condensed) {
        return {
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          'items-per-page-options': [50, 100, -1],
        };
      }
      return {};
    },
    id() {
      if (this.condensed) {
        return 'tableHeaderId';
      }
      return '';
    },
  },
  methods: {
    formatGroups(groups) {
      return groups.join(', ');
    },
  },
};
</script>

<style>
#tableHeaderId thead th {
  border-bottom: medium solid rgba(0, 0, 0, 1);
  border-top: medium solid rgba(0, 0, 0, 1);
}
#tableHeaderId thead th:first-child {
  border-left: medium solid rgba(0, 0, 0, 1);
}
#tableHeaderId thead th:last-child {
  border-right: medium solid rgba(0, 0, 0, 1);
}
</style>
